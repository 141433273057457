import moment from "moment";

export function calculateAge(dob) {
  const dobDate = new Date(dob);
  const today = new Date();

  let age = today.getFullYear() - dobDate.getFullYear();
  const m = today.getMonth() - dobDate.getMonth();

  if (m < 0 || (m === 0 && today.getDate() < dobDate.getDate())) {
    age--;
  }
  return age;
}

export function toTitleCase(str) {
  return str.replace(/\w\S*/g, function (txt) {
    return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
  });
}

export const formatDate = (dateString) => {
  const date = new Date(dateString + "T00:00:00");
  const month = String(date.getMonth() + 1).padStart(2, "0");
  const day = String(date.getDate()).padStart(2, "0");

  return `${month}/${day}/${date.getFullYear()}`;
};

export const InputformatDate = (dateString) => {
  const date = new Date(dateString);

  const month = String(date.getMonth() + 1).padStart(2, "0");
  const day = String(date.getDate()).padStart(2, "0");
  const year = date.getFullYear();

  return `${month}/${day}/${year}`;
};
export function formatCurrency(amount) {
  if (amount == null) {
    return "N/A";
  }
  const formattedAmount = new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: "USD",
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  }).format(amount);

  return formattedAmount;
}
export const formatToMMDDYYYY = (isoDateString) => {
  if (!isoDateString) {
    return "Invalid Date";
  }
  const datePart = isoDateString.split("T")[0];
  const [year, month, day] = datePart.split("-");

  if (!year || !month || !day) {
    return "Invalid Date";
  }

  return `${month}/${day}/${year}`;
};

export const formularyToMMDDYYYY = (isoDateString) => {
  if (!isoDateString) {
    return "Invalid Date";
  }
  const [year, month, day] = isoDateString.split("-");
  if (!year || !month || !day) {
    return "Invalid Date";
  }
  return `${month}/${day}/${year}`;
};

export function formatDateAndTime(date) {
  return moment(date).format("MM/DD/YY");
}