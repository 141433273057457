import React, { useState, useEffect, useCallback } from "react";
import { UserManager } from "oidc-client";
import { hl7Instance } from "../../api/axios";
import oidcConfig from "../../config/oidc-config";
import PerfectScrollbar from "react-perfect-scrollbar";
import { Link } from "react-router-dom";
import "./Searchprovider.scss";
import { toTitleCase } from "../../utils/Utils";
const userManager = new UserManager(oidcConfig);

async function getAccessToken() {
  try {
    const user = await userManager.getUser();
    if (!user) {
      throw new Error("User is not authenticated");
    }
    return user.access_token;
  } catch (error) {
    console.error("Error fetching access token:", error);
    throw error;
  }
}

const ProviderSearch = () => {
  const [searchQuery, setSearchQuery] = useState("");
  const [providers, setProviders] = useState([]);
  const [nextUrl, setNextUrl] = useState("");
  const [prevUrls, setPrevUrls] = useState([]);
  const [error, setError] = useState(null);
  const [totalRecords, setTotalRecords] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [paginationUrls, setPaginationUrls] = useState({
    current: "",
    next: "",
    prev: "",
  });
  const initialUrl = "";
  const [urlStack, setUrlStack] = useState([initialUrl]);
  const [npiQuery, setNpiQuery] = useState("");

  const handleClearForm = () => {
    setSearchQuery("");
    setNpiQuery("");
    handleSearch();
  };
  const handleSearch = useCallback(
    async (page = 1) => {
      try {
        setError(null);
        const accessToken = await getAccessToken();

        const nameQueryParts = searchQuery.trim()
          ? searchQuery
              .trim()
              .split(/\s+/)
              .map(
                (name, index) =>
                  `name${index > 0 ? ":contains" : ""}=${encodeURIComponent(
                    name
                  )}`
              )
          : [];

        const npiQueryPart = npiQuery
          ? `identifier=${encodeURIComponent(npiQuery)}`
          : "";
        const searchParts = [...nameQueryParts, npiQueryPart]
          .filter(Boolean)
          .join("&");
        const endpoint =
          searchParts.length > 0
            ? `/Practitioner?${searchParts}`
            : `/Practitioner`;
        const response = await hl7Instance.get(endpoint, {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        });
        const data = response.data;
        setProviders(data.entry || []);
        setTotalRecords(data.total || 0);
        const nextLink = data.link?.find((l) => l.relation === "next");
        const baseURL = hl7Instance.defaults.baseURL || "";
        const fullURL = `${baseURL}${endpoint}`;
        setUrlStack([fullURL]);
        if (nextLink) {
          setPaginationUrls((prev) => ({
            ...prev,
            current: fullURL,
            next: nextLink.url,
            prev: prevUrls[prevUrls.length - 1] || "",
          }));
          setNextUrl(nextLink.url);
        }
      } catch (error) {
        setError("An error occurred while searching. Please try again.");
        console.error(error);
      }
    },
    [searchQuery, npiQuery, prevUrls]
  );

  const fetchNextPage = async () => {
    try {
      setError(null);
      const accessToken = await getAccessToken();
      const response = await hl7Instance.get(paginationUrls.next, {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      });

      const data = response.data;
      const nextLink = data.link.find((l) => l.relation === "next");

      setUrlStack((prevUrls) => [...prevUrls, paginationUrls.current]);

      setPaginationUrls({
        current: paginationUrls.next,
        next: nextLink ? nextLink.url : "",
        prev: urlStack[urlStack.length - 1] || "",
      });

      setProviders(data.entry || []);
      setCurrentPage((prevPage) => prevPage + 1);
      if (totalRecords === null) {
        setTotalRecords(data.total || 0);
      }
    } catch (error) {
      setError(
        "An error occurred while fetching the next page. Please try again."
      );
    }
  };

  const fetchPreviousPage = async () => {
    try {
      setError(null);
      if (urlStack.length <= 1) return;
      const prevUrl = urlStack[urlStack.length - 1];

      const accessToken = await getAccessToken();
      const response = await hl7Instance.get(prevUrl, {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      });

      const data = response.data;
      const nextLink = data.link.find((l) => l.relation === "next");

      setUrlStack((prevUrls) => {
        const newUrls = [...prevUrls];
        newUrls.pop();
        return newUrls;
      });

      setPaginationUrls({
        current: prevUrl,
        next: nextLink ? nextLink.url : "",
        prev: urlStack[urlStack.length - 2] || "", // Set the new previous URL
      });

      setProviders(data.entry || []);
      setCurrentPage((prevPage) => prevPage - 1);
      if (totalRecords === null) {
        setTotalRecords(data.total || 0);
      }
    } catch (error) {
      setError(
        "An error occurred while fetching the previous page. Please try again."
      );
    }
  };

  useEffect(() => {
    handleSearch();
  }, []);

  const ITEMS_PER_PAGE = 10;
  const totalPages = Math.ceil(totalRecords / ITEMS_PER_PAGE);
  return (
    <>
      <div className="main-content">
        <div className="content-header">
          <h1 className="page-header">Provider Directory</h1>
        </div>
        <div className="card">
          <div className="card-body">
            {error && (
              <div className="alert alert-danger" role="alert">
                {error}
              </div>
            )}
            <div className="card-form form-content mt-0">
              <div className="row">
                <div className="col-sm-6 col-md-4 col-xl-4 col-xxl-3 mb-4 mb-md-0">
                  <label htmlFor="SearchProvider" className="d-flex form-label">
                    Search Provider Name
                  </label>
                  <div className="form-field position-relative">
                    <input
                      id="SearchProvider"
                      type="text"
                      className="form-control h-48"
                      placeholder="Enter Provider Name"
                      value={searchQuery}
                      onChange={(e) => setSearchQuery(e.target.value)}
                    />
                    <span className="field-icon position-absolute top-50 translate-middle-y">
                      <i className="fh_search"></i>
                    </span>
                  </div>
                </div>
                <div className="col-sm-6 col-md-4 col-xl-4 col-xxl-3 mb-4 mb-md-0">
                  <label htmlFor="SearchProvider" className="d-flex form-label">
                    Search Provider NPI ID
                  </label>
                  <div className="form-field position-relative">
                    <input
                      type="text"
                      className="form-control h-48"
                      placeholder="Enter NPI ID"
                      value={npiQuery}
                      onChange={(e) => setNpiQuery(e.target.value)}
                    />
                    <span className="field-icon position-absolute top-50 translate-middle-y">
                      <i className="fh_search"></i>
                    </span>
                  </div>
                </div>
                <div className="d-flex align-items-md-end justify-content-center col-12 col-md-auto col-sm-12">
                  <button
                    type="button"
                    className="btn btn-primary h-48 me-3"
                    onClick={handleSearch}
                  >
                    <span>Search</span>
                  </button>
                  <button
                    type="button"
                    className="btn btn-border-primary h-48"
                    onClick={handleClearForm}
                  >
                    <span>Clear</span>
                  </button>
                </div>
              </div>
            </div>
            <div className="card-content">
              <div className="content-subheader d-flex align-items-center">
                <h2 className="page-subheader me-3">Provider List</h2>
                <span className="total-record">
                  [ Total Providers: {totalRecords} ]
                </span>
              </div>
              <PerfectScrollbar className="custom-main-table table-responsive mb-2">
                <table className="table">
                  <thead className="sticky-top">
                    <tr>
                      <th>ID</th>
                      <th>Provider Name</th>
                      <th>Specialty</th>
                      <th>NPI</th>
                    </tr>
                  </thead>
                  <tbody>
                    {providers?.map((entry) => (
                      <tr key={entry.resource.id}>
                        <td>
                          <Link to={`/Provider/${entry.resource.id}`}>
                            {entry.resource.id}
                          </Link>
                        </td>
                        <td>
                          {entry.resource.name &&
                          entry.resource.name.length > 0 ? (
                            <>
                              {entry.resource.name[0].given
                                ? toTitleCase(
                                    entry.resource.name[0].given.join(" ")
                                  )
                                : ""}{" "}
                              {entry.resource.name[0].family
                                ? toTitleCase(entry.resource.name[0].family)
                                : ""}
                            </>
                          ) : (
                            ""
                          )}
                        </td>
                        <td>
                          {toTitleCase(
                            entry.resource.qualification &&
                              entry.resource.qualification.length > 0
                              ? entry.resource.qualification[0].code.text
                              : "Not Available"
                          )}
                        </td>
                        <td>
                          {entry.resource.identifier &&
                          entry.resource.identifier.length > 0
                            ? entry.resource.identifier[0].value
                            : ""}
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </PerfectScrollbar>
              <div className="d-flex justify-content-end">
                <nav
                  aria-label="Page navigation"
                  className="d-flex align-items-center"
                >
                  <span className="pagnination-info me-2">Page</span>
                  <ul className="pagination d-flex mb-0">
                    {currentPage > 1 && (
                      <li className="page-item">
                        <button
                          className="page-link"
                          onClick={fetchPreviousPage}
                          disabled={currentPage === 1}
                        >
                          <i className="fh_arrow_left_line"></i>
                        </button>
                      </li>
                    )}
                    <li className="page-item">
                      <button className="page-link current-page">
                        {currentPage}
                      </button>
                    </li>
                    <li className="page-item">
                      <button
                        className="page-link me-0"
                        onClick={fetchNextPage}
                        disabled={currentPage >= totalPages}
                      >
                        <i className="fh_arrow_right_line"></i>
                      </button>
                    </li>
                  </ul>
                </nav>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default ProviderSearch;